<template>
  <footer>
    <div class="footer-container p-5 surface-overlay border-round mt-4">
      <p class="text-center">
        Made by <a href="https://beautec.ca">Alexandre Beaupré</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "DefaultFooter",
};
</script>
