<template>
  <div class="home">
    <h1>TimeDeci</h1>
    <h4>
      Weekday Hours Converter - This tool will convert your hours from the
      12-hour format to the decimal format.
    </h4>
    <router-link to="/app">Access app</router-link>
  </div>
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style scoped>
.home {
  text-align: center;
  margin-top: 50px;
}
</style>
