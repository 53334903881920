import { createApp } from "vue";
import App from "./App.vue";

//import VueI18n from "./libs/i18n/index.js";

import PrimeVue from "primevue/config";
import "primevue/resources/themes/lara-dark-blue/theme.css"; // theme
import "primevue/resources/primevue.min.css"; // core css
import "primeicons/primeicons.css"; // icons
import "primeflex/primeflex.css"; // flex
import "./assets/css/timedeci.scss"; // custom css

import PrimeButton from "primevue/button";
import PrimeConfirmationService from "primevue/confirmationservice";
import PrimeToast from "primevue/toast";
import PrimeToastService from "primevue/toastservice";
import PrimeToggleButton from "primevue/togglebutton";
import PrimeTooltip from "primevue/tooltip";
import PrimeDialogService from "primevue/dialogservice";
import PrimeRipple from "primevue/ripple";
import PrimePanel from "primevue/panel";
import PrimeInputText from "primevue/inputtext";
import PrimeInputNumber from "primevue/inputnumber";
import PrimeSplitButton from "primevue/splitbutton";
import PrimeKnob from "primevue/knob";

const app = createApp(App);

app.use(router);
app.use(PrimeVue, { ripple: true });

app.use(PrimeConfirmationService);
app.use(PrimeToastService);
app.use(PrimeDialogService);

import router from "./router";

app.directive("tooltip", PrimeTooltip);
app.directive("ripple", PrimeRipple);

app.component("PrimeButton", PrimeButton);
app.component("PrimeToast", PrimeToast);
app.component("PrimeToggleButton", PrimeToggleButton);
app.component("PrimePanel", PrimePanel);
app.component("PrimeInputText", PrimeInputText);
app.component("PrimeInputNumber", PrimeInputNumber);
app.component("PrimeSplitButton", PrimeSplitButton);
app.component("PrimeKnob", PrimeKnob);

app.mount("#app");
