<template>
  <div class="w-11 p-2 m-auto">
    <DefaultNavigation></DefaultNavigation>
    <DefaultToast />
    <router-view />
    <DefaultFooter></DefaultFooter>
  </div>
</template>

<script>
import DefaultToast from "./components/Default-Toast.vue";
import DefaultNavigation from "./components/Default-Navigation.vue";
import DefaultFooter from "./components/Default-Footer.vue";

export default {
  name: "App",
  components: {
    DefaultToast,
    DefaultNavigation,
    DefaultFooter,
  },
};
</script>
