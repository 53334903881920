<template>
  <Menubar :model="menuItems" class="px-4 mb-4">
    <template #start>
      <h3 class="pr-2">TimeDeci</h3>
    </template>
    <template #item="{ item, props, hasSubmenu, root }">
      <router-link
        v-if="item.route"
        v-slot="{ href, navigate }"
        :to="{ name: item.route }"
        :disabled="item.disabled"
        custom
      >
        <a v-ripple :href="href" v-bind="props.action" @click="navigate">
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
          <Badge
            v-if="item.badge"
            :class="{ 'ml-auto': !root, 'ml-2': root }"
            :value="item.badge"
          />
          <span
            v-if="item.shortcut"
            class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1"
            >{{ item.shortcut }}</span
          >
          <i
            v-if="hasSubmenu"
            :class="[
              'pi pi-angle-down text-primary',
              { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root },
            ]"
          ></i>
        </a>
      </router-link>
    </template>
    <template #end>
      <div class="flex align-items-center gap-2">
        <Button label="Connexion" icon="pi pi-user" disabled />
        <!-- Added login icon -->
      </div>
    </template>
  </Menubar>
</template>

<script>
import Menubar from "primevue/menubar";
import Badge from "primevue/badge";
import Button from "primevue/button";

export default {
  name: "DefaultNavigation",
  components: {
    Menubar,
    Badge,
    Button,
  },
  data() {
    return {
      menuItems: [
        { label: "Home", icon: "pi pi-home", disabled: true },
        {
          label: "Hours to Decimal Converter",
          icon: "pi pi-clock",
          route: "Hours to Decimal",
          disabled: false,
        },
      ],
    };
  },
};
</script>
