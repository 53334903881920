<template>
  <div>
    <div class="top-container grid">
      <div class="title col-6">
        <h1 class="flex align-items-center">
          Weekday Hours Converter
          <PrimeButton
            v-tooltip="{
              value: `This tool will convert your hours from the 12-hour format to the decimal
        format.`,
              position: 'bottom',
              tooltipStyleClass: 'p-tooltip-custom',
              mouseTrack: true,
              mouseTrackTop: 15,
              mouseTrackLeft: 15,
            }"
            icon="pi pi-info-circle"
            severity="info"
            class="ml-3 p-button-rounded p-button-plain p-button-lg"
            rounded
            outlined
          />
        </h1>
      </div>
      <div class="actions col-6 flex justify-content-end">
        <PrimeSplitButton
          label="Save"
          icon="pi pi-save"
          @click="save"
          :model="actionsItems"
          class="align-items-center"
          severity="success"
          disabled
        />
      </div>
    </div>
    <div class="total-week-hours">
      <h2>
        Total Week Hours (decimals): {{ calculateTotalWeekHours().toFixed(2) }}
      </h2>
    </div>
    <div class="week grid">
      <PrimePanel
        v-for="(day, dayIndex) in days"
        :key="dayIndex"
        :activeIndex="0"
        class="sm:mx-2 sm:pb-4 lg:col-4 lg:pb-3 lg:mx-0"
        :header="day + ' // ' + totalDecimalHours[dayIndex].toFixed(2)"
      >
        <div
          v-for="inputIndex in 4"
          :key="inputIndex"
          class="flex flex-wrap p-1"
        >
          <div class="hours font-bold p-2 flex-1">
            <label>Hours: </label>
            <PrimeInputNumber
              v-model.number="hours[dayIndex][inputIndex - 1].hours"
              :step="1"
              :min="0"
              :max="99"
              :allowEmpty="false"
              @input="calculateTotalHours(dayIndex)"
              class="w-full p-fluid"
            />
          </div>
          <div class="minutes font-bold p-2 flex-1">
            <label>Minutes: </label>
            <PrimeInputNumber
              v-model.number="hours[dayIndex][inputIndex - 1].minutes"
              :step="1"
              :min="0"
              :max="59"
              :allowEmpty="false"
              @input="calculateTotalHours(dayIndex)"
              class="w-full p-fluid"
            />
          </div>
        </div>
        <div class="total font-bold text-center p-2">
          <h3>
            Total Decimal Hours: {{ totalDecimalHours[dayIndex].toFixed(2) }}
          </h3>
        </div>
      </PrimePanel>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";

export default {
  name: "HoursConverter",
  data() {
    return {
      actionsItems: [
        {
          label: "Save",
          icon: "pi pi-save",
          command: () => {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Hours saved successfully",
              life: 3000,
            });
          },
        },
        {
          label: "Reset",
          icon: "pi pi-trash",
          command: () => {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Hours reset successfully",
              life: 3000,
            });
          },
        },
      ],
    };
  },
  setup() {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const hours = ref(
      Array(7)
        .fill()
        .map(() =>
          Array(4)
            .fill()
            .map(() => ({ hours: 0, minutes: 0 }))
        )
    );

    const totalDecimalHours = computed(() => {
      return hours.value.map((dayHours) => {
        let totalHours = 0;
        for (const { hours: dayHour, minutes } of dayHours) {
          totalHours += parseFloat(dayHour) + parseFloat(minutes) / 60;
        }
        return totalHours;
      });
    });

    function calculateTotalHours(dayIndex) {
      let totalHours = 0;
      for (const { hours: dayHour, minutes } of hours.value[dayIndex]) {
        totalHours += parseFloat(dayHour) + parseFloat(minutes) / 60;
      }
      totalDecimalHours.value[dayIndex] = totalHours;
    }

    function calculateTotalWeekHours() {
      let totalHours = 0;
      for (const dayHours of totalDecimalHours.value) {
        totalHours += dayHours;
      }
      return totalHours;
    }

    // Watch for changes in the 'hours' ref to recalculate totalDecimalHours
    watch(
      hours,
      () => {
        hours.value.forEach((dayHours, dayIndex) => {
          let totalHours = 0;
          for (const { hours: dayHour, minutes } of dayHours) {
            totalHours += parseFloat(dayHour) + parseFloat(minutes) / 60;
          }
          totalDecimalHours.value[dayIndex] = totalHours;
        });
      },
      { deep: true }
    );

    return {
      days,
      hours,
      totalDecimalHours,
      calculateTotalHours,
      calculateTotalWeekHours,
    };
  },
};
</script>
