// router/index.js
import { createRouter, createWebHistory } from "vue-router";
import HoursConverter from "../views/HoursConverter.vue";
import HomePage from "../views/HomePage.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      alias: "/app",
      name: "Hours to Decimal",
      component: HoursConverter,
    },
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: HomePage,
    },
  ],
});

export default router;
