<template>
  <div>
    <Toast
      ref="toast"
      :position="position"
      :severity="severity"
      :life="life"
      :closable="closable"
    ></Toast>
  </div>
</template>

<script>
import Toast from "primevue/toast";

export default {
  name: "Default-Toast",
  components: {
    Toast,
  },
  data() {
    return {
      position: "top-right",
      severity: "info",
      life: 3000,
      closable: true,
    };
  },
  methods: {
    showToast() {
      this.$refs.toast.show({
        severity: this.severity,
        summary: "Toast Message",
        detail: "This is a toast message.",
        life: this.life,
        closable: this.closable,
      });
    },
  },
};
</script>
